import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const v2Class = {
    // 获取所有班级
    getAll(params) {
        return request({
            url: '/v1/school_api/grade_class/index',
            params,
        })
    },
    // 获取指定专业班级
    getList(params) {
        return request({
            url: '/v1/school_api/arrange/classes',
            params,
        })
    },
    // 班级毕业
    graduate(id) {
        return request({
            url: '/v1/school_api/grade_class/graduate',
            method: 'post',
            data: {
                id,
            },
        })
    }
}
export default v2Class
