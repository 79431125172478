<template>
  <div>
    <v-index>
      <el-breadcrumb>
        <el-breadcrumb-item>教务</el-breadcrumb-item>
        <el-breadcrumb-item>智能排课</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">添加学期课程</a></el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content">
        <div class="content-step">
          <el-steps :active="step" finish-status="success">
            <el-step title="选择学期"></el-step>
            <el-step title="选择班级"></el-step>
            <el-step title="选择课程"></el-step>
            <el-step title="课程属性"></el-step>
            <el-step title="添加完成"></el-step>
          </el-steps>
        </div>

        <template v-if="step === 0">
          <el-row>
            <el-col :span="8">
              <div class="box">
                <div class="content-title">校区</div>
                <ul class="discipline">
                  <template v-for="item in campus">
                    <li :key="item.id" :class="{ selected: selectionCampus === item.id }" @click="onSelectionCampus(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

            <el-col :span="8">
              <div class="box">
                <div class="content-title">学期</div>
                <div v-if="semesters.length === 0" class="empty-data">
                  无学期数据
                </div>
                <ul class="discipline">
                  <template v-for="item in semesters">
                    <li :key="item.id" :class="{ selected: selectionSemester === item.id }" @click="onSelectionSemester(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

          </el-row>
        </template>

        <!-- 选择班级 -->
        <div v-show="step === 1">
          <el-row>
            <el-col :span="8">
              <div class="box">
                <div class="content-title">所选专业：</div>
                <ul class="discipline">
                  <template v-for="item in zyList">
                    <li :key="item.id" :class="{ selected: selection === item.id }" @click="onSelection(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

            <el-col :span="16">
              <div class="box">
                <div class="content-title">已选班级：</div>
                <ul class="class-list">
                  <template v-for="item in classes">
                    <li :key="item.id" v-if="selectionClass.includes(item.id)">
                      <el-tag closable @close="onCancelClass(item)">{{item.name}}</el-tag>
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 选择课程 -->
        <div v-show="step === 2">
          <el-row>
            <el-col :span="8">
              <div class="box">
                <div class="content-title">课程列表：</div>
                <div class="content-search">
                  <el-input v-model="searchDisciplineName" placeholder="输入课程名称搜索" @input="onSearchDiscipline" />
                </div>
                <div v-if="disciplines.length === 0" class="empty-data">
                  找不到课程数据
                </div>
                <ul class="discipline">
                  <template v-for="item in disciplines">
                    <li :key="item.id" :class="{ selected: selectionDiscipline.includes(item.id) }" @click="onSelectionDiscipline(item)">
                      {{ item.name }}
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>

            <el-col :span="16">
              <div class="box">
                <div class="content-title">已选课程：</div>
                <ul class="class-list">
                  <template v-for="item in disciplines">
                    <li :key="item.id" v-if="selectionDiscipline.includes(item.id)">
                      <el-tag closable @close="onSelectionDiscipline(item)">{{item.name}}</el-tag>
                    </li>
                  </template>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 课程属性 -->
        <div v-show="step === 3">
          <el-divider />

          <div class="grade-class">
            <label class="grade-class-label">添加班级</label>
            <div class="grade-class-wrapper">
              <template v-for="item in classes.filter(_ => selectionClass.includes(_.id))">
                <el-tag>{{item.name}}</el-tag>
              </template>
            </div>
          </div>

          <el-divider />

          <div class="course-row header">
            <el-row>
              <el-col :span="6">
                课程
              </el-col>
              <el-col :span="3">
                连排课时
              </el-col>
              <el-col :span="3">
                周课时
              </el-col>
              <el-col :span="3">
                学分
              </el-col>
              <el-col :span="2">
                授课方式
              </el-col>
              <el-col :span="2">
                分制类型
              </el-col>
              <el-col :span="2">
                考试方式
              </el-col>
            </el-row>
          </div>
          <div>
            <template v-for="item in formData.courses">
              <div :key="item.discipline_id" class="course-row">
                <el-row>
                  <el-col :span="6">
                    <div class="course-row-box">
                      {{item.name}}
                    </div>
                  </el-col>

                  <el-col :span="3">
                    <div class="course-row-box">
                      <el-input-number :min="0" size="small" v-model="item.series" />
                    </div>
                  </el-col>

                  <el-col :span="3">
                    <div class="course-row-box">
                      <el-input-number :min="0" size="small" v-model="item.week_hour" />
                    </div>
                  </el-col>

                  <el-col :span="3">
                    <div class="course-row-box">
                      <el-input-number :min="0" size="small" v-model="item.score" />
                    </div>
                  </el-col>

                  <el-col :span="2">
                    <div class="course-row-box">
                      <el-select size="small" v-model="item.mode">
                        <el-option :value="0" label="行政班授课" />
                        <el-option :value="1" label="合班授课" />
                        <el-option :value="2" label="选修班授课" />
                      </el-select>
                    </div>
                  </el-col>

                  <el-col :span="2">
                    <div class="course-row-box">
                      <el-select size="small" v-model="item.score_type">
                        <el-option value="百分制" />
                      </el-select>
                    </div>
                  </el-col>

                  <el-col :span="2">
                    <div class="course-row-box">
                      <el-select size="small" v-model="item.test_type">
                        <el-option :value="0" label="考试" />
                        <el-option :value="1" label="考查" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
          </div>
        </div>

        <div v-show="step === 4">
          <el-result icon="success" title="添加成功" :subTitle="selectionDiscipline.length + '节课程已添加'">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="$router.back()">返回</el-button>
            </template>
          </el-result>
        </div>

        <div style="text-align: center; padding-top: 20px" v-if="step < 4">
          <el-button @click="onPrev" :disabled="step === 0" :loading="subing">上一步</el-button>
          <el-button @click="onNext" type="primary" :loading="subing">{{step===3 ? '提交' : '下一步'}}</el-button>
        </div>
      </div>
    </v-index>
  </div>
</template>

<script>
import VIndex from "@/components/common";
import v2Class from "@/api/v2/class";
import arrange from "@/api/v2/arrange";
import _ from "lodash";
export default {
  name: "create",
  components: {VIndex},
  data() {
    return {
      zyList: [],
      zyTotal: 0,
      // 已选校区
      selectionCampus: 0,
      // 已选学期
      selectionSemester: 0,
      // 已选专业
      selection: 0,
      // 班级数据
      classes: [],
      // 当前步骤
      step: 0,
      // 已选班级
      selectionClass: [],
      // 课程列表
      disciplines: [],
      searchDisciplineName: '',
      // 已选课程
      selectionDiscipline: [],
      // 表单数据
      formData: {
        courses: []
      },
      subing: false
    }
  },
  computed: {
    campus() {
      return this.$store.getters['common/campus']
    },
    semesters() {
      return this.$store.getters['common/semesters'].filter(_ => this.selectionCampus === _.school_campus_id)
    },
  },
  mounted() {
    this.getDisciplineType()
    this.getZy()
  },
  methods: {
    // 校区选择
    onSelectionCampus(item) {
      this.selectionCampus = item.id;
    },
    // 学期选择
    onSelectionSemester(item) {
      this.selectionSemester = item.id;
    },
    getZy() {
      this.$api.setting.getZyList({ limit: 100 })
          .then(result => {
            if (result.data.code === 1) {
              this.zyList = result.data.data.rows;
              this.zyTotal = result.data.data.total;
            }
          })
    },
    /**
     * 专业被选择
     * @param item
     */
    onSelection(item) {
      this.selection = item.id;
      this.getClasses(item.id)
    },
    /**
     * 获取班级
     */
    async getClasses(zyId) {
      const resp = await v2Class.getList({ zy_id: zyId })
      this.classes = resp.data.rows
      this.selectionClass = this.classes.map(item => item.id)
    },
    /**
     * 取消班级选择
     */
    onCancelClass(item) {
      this.selectionClass = this.selectionClass.filter(_ => _ !== item.id)
    },
    /**
     * 搜索课程
     */
    onSearchDiscipline: _.debounce(function () {
      this.getDiscipline()
    }, 500),
    /**
     * 获取专业课程
     */
    async getDiscipline(zyId) {
      const resp = await this.$api.setting.subjectIndex({
        search: this.searchDisciplineName,
      })
      if (resp.data.code === 1) {
        this.disciplines = resp.data.data.rows;
      }
    },
    /**
     * 获取课程类型
     */
    getDisciplineType() {
      this.$api.setting.subjectTypeList()
    },
    /**
     * 上一步
     */
    onPrev() {
      if (this.step === 0) {
        return
      }
      this.step--
    },
    /**
     * 下一步
     */
    onNext() {
      if (this.step === 3) {
        this.onSubmit()
        return
      }
      if (this.step === 0) {
        if (this.selectionSemester === 0) {
          this.$message.error('请选择学期')
          return
        }
      }
      if (this.step === 1) {
        if (this.selectionClass.length === 0) {
          this.$message.error('请选择班级')
          return
        }
      }
      this.step++

      if (this.step === 2) {
        this.getDiscipline(this.selection)
      }
      else if (this.step === 3) {
        this.formData.courses = this.disciplines
            .filter(_ => this.selectionDiscipline.includes(_.id))
            .map(_ => ({
              discipline_id: _.id,
              type: 0,
              series: 0,
              name: _.name,
              mode: 0,
              zy_id: this.selection,
              week_hour: 30,
              test_type: 0,
              score: 0,
              score_type: '百分制'
            }))
      }
    },
    /**
     * 提交
     */
    async onSubmit() {
      const formData = []
      this.selectionClass.forEach(classId => {
        this.formData.courses.forEach(course => {
          formData.push({
            discipline_id: course.discipline_id,
            class_id: classId,
            type: course.type,
            series: course.series,
            mode: course.mode,
            zy_id: course.zy_id,
            week_hour: course.week_hour,
            test_type: course.test_type,
            score: course.score,
            score_type: course.score_type
          })
        })
      })
      this.subing = true
      await arrange.submit(this.selectionSemester, formData)
          .finally(() => this.subing = false)
      this.step++;
    },
    /**
     * 选择课程
     */
    onSelectionDiscipline(item) {
      const index = this.selectionDiscipline.findIndex(_ => _ === item.id)
      if (index === -1) {
        this.selectionDiscipline.push(item.id)
      } else {
        this.selectionDiscipline.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  &-step {
    padding: 0 20px;
  }
  &-title {
    font-weight: 700;
    margin-bottom: 10px;
  }
  &-search {
    margin-bottom: 20px;
  }
}
.empty-data {
  color: #999;
  margin-top: 15px;
  border: solid 1px #f3f3f3;
  padding: 30px;
}
.discipline {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
  font-size: 14px;
  li {
    padding: 4px 8px;
    cursor: pointer;
    border: solid 1px #d3d3d3;
    margin-bottom: 6px;
    &.selected {
      background-color: rgb(179, 216, 255);
      border-color: rgb(179, 216, 255);
    }
  }
}
.box {
  padding: 10px;
}
.class-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin-right: 10px;
  }
}

.grade-class {
  padding: 10px 10px 0;
  display: flex;
  align-items: center;

  &-name {
  }
  &-wrapper {
    margin-left: 10px;
  }
  ::v-deep .el-tag + .el-tag {
    margin-left: 10px;
  }
}

.course-row {
  padding: 10px;
  &.header {
    margin-top: 30px;
    font-weight: bold;
  }
  &:hover {
    background-color: rgb(254, 240, 240);
  }
  &-box {
    line-height: 35px;
    padding: 0 4px;
  }
}
</style>