import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const arrange = {
    // 导入模板
    importTemplate() {
        return base.host + '/template/智能排课导入模板.xlsx'
    },
    // 学期课程列表
    getList(params) {
        return request({
            url: '/v2/school_api/arrange/course/index',
            params,
        })
    },
    // 增加学期课程
    submit(semesterId, data) {
        return request({
            url: '/v2/school_api/arrange/course/submit',
            method: 'post',
            data: {
                semester_id: semesterId,
                courses: data
            },
        })
    },
    // 编辑学期课程
    edit(data) {
        return request({
            url: '/v2/school_api/arrange/course/edit',
            method: 'post',
            data,
        })
    },
    // 删除学期课程
    del(id) {
        return request({
            url: '/v2/school_api/arrange/course/del',
            method: 'post',
            data: {
                id,
            }
        })
    },
    // 导入学期课程
    import(file) {
        const data = new FormData()
        data.append('file', file)
        return request({
            url: '/v2/school_api/arrange/course/imports',
            method: 'post',
            data,
        })
    },
    // 修改任课老师
    changeTeacher(data) {
        return request({
            url: '/v2/school_api/arrange/course/change_teacher',
            method: 'post',
            data
        })
    }
}
export default arrange
